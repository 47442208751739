"use client";
import { useId } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { RadioInput } from "../../../form/input/RadioInput/RadioInput";

import styles from "./EnergySelect.module.scss";

interface EnergySelectProps {}

export const EnergySelect = ({}: EnergySelectProps) => {
	const id = useId();
	const router = useRouter();
	const searchParams = useSearchParams();
	const pathname = usePathname();

	return (
		<div
			className={styles["energy-select"]}
			role="radiogroup"
			aria-labelledby={"product-results-energytype-legend-" + id}
		>
			<span
				className="sr-only"
				id={"product-results-energytype-legend-" + id}
			>
				Select electricity/gas:
			</span>
			<RadioInput
				key="electricity"
				label="Electricity"
				value="electricity"
				role="radio"
				name={`product-results-energytype-${id}`}
				checked={searchParams.get("energy") === "electricity" || !searchParams.get("energy")}
				onChange={e => {
					const params = new URLSearchParams(searchParams);
					params.set("energy", e.target.value);
					router.replace(`${pathname}?${params.toString()}`);
				}}
			/>
			<RadioInput
				key="gas"
				label="Gas"
				value="gas"
				role="radio"
				name={`product-results-energytype-${id}`}
				checked={searchParams.get("energy") === "gas"}
				onChange={e => {
					const params = new URLSearchParams(searchParams);
					params.set("energy", e.target.value);
					router.replace(`${pathname}?${params.toString()}`);
				}}
			/>
		</div>
	);
};
