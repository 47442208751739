"use client";
import styles from "./ErrorCard.module.scss";
import { useRouter } from "next/navigation";
import LinkSolstice from "../../../LinkSolstice/LinkSolstice";
import { Button } from "../../../../shared/Button/Button";
import { errorMessages } from "@/utilities/constants";
import { MaterialIcon } from "../../../MaterialIcon/MaterialIcon";

interface ErrorCardProps {
	error: Error;
}

export const ErrorCard = ({ error }: ErrorCardProps) => {
	const router = useRouter();
	const { title, body, link } =
		errorMessages[
			(error.message && error.message === "noPlan" ? "noPlan" : "generic") ?? "generic"
		];

	return (
		<div>
			{error && title && (
				<div className={styles["error-card"]}>
					<div className={styles["error-card__icon"]}>
						<MaterialIcon name="warning" />
					</div>
					<h2 className={styles["error-card__heading"]}>{title}</h2>
					{body && (
						<div className={styles["error-card__content"]}>
							{body}
							{link && (
								<LinkSolstice
									href={link.link}
									target="_blank"
								>
									{link.title}
								</LinkSolstice>
							)}
						</div>
					)}

					<Button
						className={styles["error-card__cta"]}
						theme="tertiary"
						label={"Search again"}
						onClick={() => {
							const url = new URL(window.location.href);
							router.push(url.origin + url.pathname);
						}}
					/>
				</div>
			)}
		</div>
	);
};
