"use client";

import { useEffect, useState } from "react";
import { PipelineLocatorMap } from "../../../../blocks/PipelineLocatorBlock/PipelineLocatorMap";
import { PipelineLocatorGoogleMapsAPIProvider } from "../../../../blocks/PipelineLocatorBlock/PipelineLocatorGoogleMapsAPIProvider";

interface CheckGasAvailableCardProps {
	address: string;
	children: React.ReactNode;
}

export const CheckGasAvailableCard = ({ address, children }: CheckGasAvailableCardProps) => {
	const [isValid, setIsValid] = useState<boolean | undefined>(false);

	useEffect(() => {
		setIsValid(false);
	}, [address]);

	if (!isValid) {
		return (
			<div style={{ display: isValid === false ? "block" : "none", width: "100%" }}>
				<PipelineLocatorGoogleMapsAPIProvider>
					<PipelineLocatorMap
						address={address}
						callback={isValid => setIsValid(isValid)}
					/>
				</PipelineLocatorGoogleMapsAPIProvider>
			</div>
		);
	}

	return children;
};
